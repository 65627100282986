import React from 'react'
import { FlexibleContentRow, FlexibleContentRowProps } from './flexibleContentRow'
import { FlexibleContentWrapper, FlexibleContentWrapperProps } from './flexibleContentWrapper'

export type FlexibleContentProps =
  | ({ row: true } & FlexibleContentRowProps)
  | ({ row?: false } & FlexibleContentWrapperProps)

export const FlexibleContent: React.FC<FlexibleContentProps> = ({ row, ...props }) => {
  if (row) {
    return <FlexibleContentRow { ...props as FlexibleContentRowProps } />
  }

  return <FlexibleContentWrapper { ...props as FlexibleContentWrapperProps } />
}