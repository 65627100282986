import React, { ComponentProps } from 'react'
import styled from 'styled-components'
import { StyledFlexibleContentRow } from './flexibleContentRow'

// No empty source lint fix can be removed
export const StyledFlexibleContentWrapper = styled.div`
  margin-bottom: ${props => props.theme.typography.pxToRem(112)};
  margin-top: ${props => props.theme.typography.pxToRem(125)};
  ${props => props.theme.breakpoints.down('md')} {
    margin-bottom: ${props => props.theme.typography.pxToRem(32)};
  }

  > * + * > ${StyledFlexibleContentRow} {
    margin-block-start: ${props => props.theme.custom.spacing.paragraph};
  }
`

export const StyledFcpAngleLine = styled.div`
  margin-top: 28px;
  height: 1px;
  background-color: #e9e9ed;
  padding: 1px;
  -ms-transform: rotate(-2.8deg);
  -webkit-transform: rotate(-2.8deg);
  transform: rotate(-2.8deg);
  ${props => props.theme.breakpoints.up('sm')} {
    margin-top: ${props => props.theme.typography.pxToRem(-25)};
  }
  ${props => props.theme.breakpoints.up('md')} {
    -ms-transform: rotate(-4deg);
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
    margin-top: ${props => props.theme.typography.pxToRem(-47)};
  }
  ${props => props.theme.breakpoints.up('lg')} {
    -ms-transform: rotate(-2.7deg);
    -webkit-transform: rotate(-2.7deg);
    transform: rotate(-2.7deg);
    margin-top: ${props => props.theme.typography.pxToRem(-48)};
  }
  ${props => props.theme.breakpoints.up(2500)} {
    -ms-transform: rotate(-2deg);
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
    margin-top: ${props => props.theme.typography.pxToRem(-47)};
  }
`

export type FlexibleContentWrapperProps = ComponentProps<typeof StyledFlexibleContentWrapper> & {
  //
}

export const FlexibleContentWrapper: React.FC<FlexibleContentWrapperProps> = ({ children, ...props }) => {
  return (
    <>
      <StyledFcpAngleLine id={'flexibleSlantedLine'} />
      <StyledFlexibleContentWrapper {...props}>
        {children}
      </StyledFlexibleContentWrapper>
    </>
  )
}